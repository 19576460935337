import React, { createContext, useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { useGet } from "@collabodoc/component-library";
import { API_URLS } from "./URLS";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

export const RegistrationContext = createContext({});

export const RegistrationContextProvider = () => {
  const { accessToken } = useOidcAccessToken();
  const [contactReasonId, setContactReasonId] = useState();
  const [message, setMessage] = useState({
    patientText: null,
    thoughtText: null,
    concernText: null,
    wishText: null,
  });
  const [subjectState, setSubject] = useState({
    isSelf: null,
  });
  const [patientContactInfo, setPatientContactInfo] = useState({
    phoneNumber: null,
    email: null,
  });
  const [childInfo, setChildInfo] = useState({
    firstName: null,
    lastName: null,
    personalNumber: null,
  });
  const [formIsSent, setFormIsSent] = useState(false);

  const resetForm = () => {
    setContactReasonId(undefined);

    setMessage({
      patientText: null,
      thoughtText: null,
      concernText: null,
      wishText: null,
    });

    setSubject({
      isSelf: null,
    });

    setPatientContactInfo({
      phoneNumber: null,
      email: null,
    });

    setChildInfo({
      firstName: null,
      lastName: null,
      personalNumber: null,
    });
  };

  const { doGet, response: contactReasons = [] } = useGet(
    API_URLS.CONTACTREASONS,
    accessToken,
    false
  );

  useEffect(() => {
    if (accessToken) {
      doGet();
    }
  }, [accessToken, doGet]);

  const getContactReasonName = (id) => {
    const contactReason = contactReasons?.find(
      (contactReason) => contactReason.id === id
    );
    return contactReason?.name;
  };

  const visibleContactReasons = useMemo(() => {
    return contactReasons.filter(
      (cr) => cr.targetGroup === 1 || cr.targetGroup === 3
    );
  }, [contactReasons]);

  return (
    <RegistrationContext.Provider
      value={{
        contactReasonId,
        setContactReasonId,
        message,
        setMessage,
        subjectState,
        setSubject,
        patientContactInfo,
        setPatientContactInfo,
        childInfo,
        setChildInfo,
        formIsSent,
        setFormIsSent,
        resetForm,
        contactReasons: visibleContactReasons,
        getContactReasonName,
      }}
    >
      <Outlet />
    </RegistrationContext.Provider>
  );
};
